.countdown
  display: flex
  font-size: 40px
  font-weight: 900
  line-height: 48px
  
  @media tablet
    justify-content: center

  @media mobile
    justify-content: center

  &.is-stopped
     .unit
      opacity: 0

  .countdown-block 
    align-items: center
    display: flex
    flex-direction: column
    min-width: 60px

  .unit
    transition: opacity 0.5s ease-in-out
  
  .tag 
    font-size: 13px
    line-height: 16px