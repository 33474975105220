// Summary
summary-absolute()
  transition ease transform 200ms, ease opacity 200ms
  position absolute
  top 0
  height 0
  overflow hidden
  transform translateY(-1rem)
  opacity 0
  &.active
    transform translateY(0) scale(1)
    opacity 1

.subscriptions-summary--inner
  border 1px solid #F5F5F5
  background-color #FDFDFD
  .flex-cell:not(.flex-cell-header)
    padding-left 1rem
    padding-right 1rem
    &:last-of-type
      padding-bottom 1rem

// STEP 2 is always responsive
.content-subscriptions-step-2 
  .item-summary
    summary-absolute()
    right 0
  @media all and (max-width:900px)
    .item-summary
      width 100%
  @media all and (min-width:901px)
    .item-summary
      min-width 320px
      box-shadow 0 10px 30px rgba(black,0.2)
      right 1rem
    
// STEP 3 is just absolute on responsive
.content-subscriptions-step-3, 
.content-subscriptions-step-4
  @media all and (max-width:900px)
    .item-summary
      summary-absolute()
      left 0
      width 100%

// Toggle on top    
.toggle-item-summary
  position relative
  padding-right 1rem
  display block
  &:after
    $size = 5px
    transition transform ease 150ms
    content ''
    position absolute
    right 0
    top 50%
    width 0
    height 0 
    margin-top - unit($size/2,'px')
    border-left $size solid transparent
    border-right $size solid transparent
    border-top: $size solid purple;
  &.active
    &:after
      transform rotate(180deg)
  @media all and (min-width:901px)
    &.toggle-item-summary-step-3,
    &.toggle-item-summary-step-4
      display none

main
  &.item-summary-active
    // user-select none
    &:after
      content ''
      absolute-full()
      @media all and (max-width:900px)
        background rgba(black,0.5)
      @media all and (min-width:901px)
        background rgba(black,0.0)
    .subscriptions-nav
      z-index 200
    .item-summary
      z-index 150
  &.item-summary-on-transition:after
    animation fadeLayerIn ease 200ms forwards
  &.item-summary-on-transition-reverse:after
    animation fadeLayerOut ease 200ms forwards
    
@keyframes fadeLayerIn
  0%
    opacity 0
  100%
    opacity 1
@keyframes fadeLayerOut
  0%
    opacity 1
  100%
    opacity 0