btnColours = {
  // key: background, color
  green: {
    bg: green
    bgHover: darken(green,10%)
    color: white
  }
  white: {
    bg: white
    bgHover: darken(white,10%)
    color: lightblue
  }
  lightblue: {
    bg: lightblue
    bgHover: darken(lightblue,10%)
    color: white
  }
  purple: {
    bg: purple
    bgHover: #75165C
    color: white
  }
}

btnBorderedColours = {
  green: {
    borderColor: green
  }
  purple: {
    borderColor: purple
  }
}

.btn
  transition background ease 150ms, color ease 150ms
  position relative
  text-decoration: none
  font-size 1rem
  border-radius rem(2px)
  display inline-block
  padding 0.67rem 2.75rem
  line-height 135%
  box-sizing border-box
  cursor pointer
  background-color:rgb(239, 239, 239)
  border: 2px solid rgb(239, 239, 239)
  @media mobile
    padding-top 1rem
    padding-bottom 1rem
    &.btn-block-mobile
      display block !important

  for key in btnColours
    &.btn-{key}
      background-color: btnColours[key].bg
      border: 2px solid btnColours[key].bg
      color: btnColours[key].color
      &:focus:not(:disabled),
      &:hover:not(:disabled)
        background-color: btnColours[key].bgHover
        border: 2px solid btnColours[key].bgHover
        color: btnColours[key].color
      &:active {
        box-shadow: inset 0 0 5px 1px btnColours[key].bgHover;
      }
  for key in btnBorderedColours
    &.btn-bordered-{key}
      background: white
      border:2px solid btnBorderedColours[key].borderColor
      color: btnBorderedColours[key].borderColor
      // &:hover
      //   background-color: btnBorderedColours[key].borderColor
      //   color: white

  &:active
    top 2px
  &.btn-large
    padding 1rem 2.75rem
    @media mobile
      padding-top 1.2rem
      padding-bottom 1.2rem
  &.btn-full
    display flex
    width 100%
    text-align: center
    align-items center
    justify-content center
  &.btn-half
    width 50%
  &:disabled
    background: #BEBEBE
    border-color #BEBEBE
    color white
    cursor default
  @media mobile
    &.btn-full-mobile
      width 100%
  &.btn-dense
    padding 0.3rem 2.75rem
    border-radius 0.25rem
