// Subscriptions STEP 1
// Select your trip, round or single
.grid-subscriptions-select
  
  .item-ow
    #back // #id should not be under .className but is an SVG ¯\_(ツ)_/¯
      display none
  .item-description h4
    word-break break-all
    
  @media desktop
    & > .item
      transition transform ease 300ms, box-shadow ease 300ms, border-color ease 200ms
      &:focus
        outline 0 none
        border-color purple
      &:hover,
      &:focus
        transform translateY(-0.3rem)
        box-shadow 0 10px 30px darken(#f5f5f5,10%)
        
      
  @media nondesktop
    & > .item
      padding 1rem
    .flex-2 > .item
      width 100%
      padding-bottom 1rem
      &:last-child
        padding-bottom 0
    .btn
      width 100%
    .item-description
      h4, p
        display inline-block
        vertical-align middle
      h4
        padding-right 1rem
      p
        padding-top 0
        

// Subscriptions STEP 1
// Swiper with different destinations
.subscriptions-swiper
  transition opacity ease 300ms 100ms, transform ease 300ms 100ms
  position relative
  padding-left 3rem
  padding-right 3rem
  transform translateY(0)
  
  // Handled with js/src/subscriptions.js
  &.unloaded
    transform translateY(1rem)
    opacity 0

  .swiper-container
    width 100%
    // max-width: 960px;
    margin 0 auto
  .swiper-slide
    position relative
  .slide-image
    display block
    width 100%
    user-select none
  .slide-title
    position absolute
    bottom 0.5rem
    left 0.5rem
    font-size 14px
    color white
    text-shadow: 0 0 10px #000;