.footer-wrapper
  font-size 13px
  margin-top auto

.flex-footer
  flex-display()
  flex-direction row
  justify-content space-between
  @media nonmobile
    .footer-nav
      li
        padding-right 1.5rem
        &:last-child
          padding-right 0
  @media mobile
    text-align center
    .footer-credit,
    .footer-nav 
      width 100%
    .footer-credit
      padding-bottom 0.5rem
    .footer-nav li
      display block
      padding-bottom 0.5rem
      &:last-child
        padding-bottom 0.5rem