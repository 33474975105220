// Subscriptions STEP 2
// Subscriptions select your luggage
.subscriptions-luggage-item
  display none
  flex-display()
  transition border-color ease 300ms, transform ease 300ms, box-shadow ease 300ms
  &:hover:not(.item-selected)
    border-color darken(#f5f5f5,10%)
    box-shadow 0 10px 30px darken(#f5f5f5,10%)
    transform translateY(-0.3rem)
  &.item-selected
    border-color green
    box-shadow 0 10px 30px rgba(green,0.3)
    transform translateY(-0.3rem)
      
  .el-image
    width 100%
    height auto
    background-size cover
    background-position center center
  @media desktop
    .el-content
      text-align right
  @media nondesktop
    .flex-2 > .item
      width 100%
    text-align center
    .btn
      width 100%
    .flex-2 > .item:last-child
      padding-top 1rem
  @media nonmobile
    .el-image
      width 25%
    .el-content
      width 75%
  @media mobile
    .el-image
      width 40%
    .el-content
      width 60%
      padding-bottom 1rem

.item-subscriptions-remove
  transition ease opacity 300ms
  display none
  animation fadeIn forwards 300ms
  @media nonmobile
    padding-top 1rem
  @media mobile
    padding-top 2rem

.subscriptions-luggage-item.item-selected
  .item-subscriptions-remove
    display block

#plan-notification
  .box-notification
    transition ease height 300ms, ease margin-bottom 300ms