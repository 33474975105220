/*
  DOM Structure
  .accordion-wrapper
    .accordion-item 1.* | &.accordion-item-active
      .accordion-item-header  1
      .accordion-item-content 1
        .accordion-item-content-inner 1
*/
.accordion-wrapper
  .accordion-item
    &:not(.accordion-item-active)
      cursor pointer
    &.accordion-item-active
      .accordion-item-header
        cursor pointer
      .accordion-item-content
        transform translateY(0)
        opacity 1
    // Content default
    .accordion-item-content
      transition transform ease 300ms, opacity ease 300ms, height ease 200ms
      transform translateY(0.5rem)
      overflow hidden
      opacity 0