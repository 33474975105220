// This might be useful in the future:
// https://css-tricks.com/form-validation-ux-html-css/

.content-form
  .action-spinner
    display block
    position relative
    width 24px
    height 24px
    margin-left 20px
    div
      transform-origin 12px 12px
      animation spinnerAnimation 1.2s linear infinite
      for i in 1 .. 12
        &:nth-child({i})
          transform rotate((i*30)deg)
          animation-delay i*100ms

    div:after
      content " "
      display block
      position absolute
      top 0
      left 12px
      width 2px
      height 10px
      border-radius 2px
      background white
  .text
    position relative
    width 100%
    padding 0.5rem
  .submit
    padding 0.5rem 1rem
  .text, .submit
    font-size 1rem
    // &:focus 
    //   outline 0

  .field
    line-height 100%
    position relative
    transition ease opacity 400ms
    margin-bottom 1em
    &.disabled
      opacity 0.5
      cursor not-allowed
      .text
        background-color #f1f1f1
  .field .text,
  .field .select,
  .field .radio,
  .field .checkbox
    -moz-appearance none
    -webkit-appearance none
  .field .radio,
  .field .checkbox
    &:hover
      cursor pointer

  .field .text,
  .field .select
    transition ease border 200ms, box-shadow ease 200ms
    display block
    width 100%
    border-radius 2px
    border 1px solid #E7E7E7
    color lightgray
    margin 0
    padding 0.8rem
    font-family inherit
    font-size 1rem
    @media nonmobile
      font-size 14px
  
  .field .radio,
  .field .checkbox
    margin-right 0.5rem
    
  .field .radio
    $size = 16px
    position relative
    transition background ease 200ms
    width $size
    height $size
    background white
    border 3px solid white
    border-radius 100%
    margin-left 4px
    vertical-align middle
    top -2px
    &:checked
      background purple
    &:after
      $gap = -5px
      content ''
      position absolute
      transition background-color ease 300ms
      top $gap
      left $gap
      right $gap
      bottom $gap
      background purple
      border-radius 100%
      z-index -1
    @media mobile
      width 20px
      height 20px
      
  .field .checkbox
    $size = 18px
    width $size
    height $size
    transition background ease 200ms
    border 2px solid purple
    border-radius 2px
    position relative
    display inline-block
    vertical-align sub
    &:checked
      background-color purple
    &:before
      transition transform ease 50ms 200ms
      content ""
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTQgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICA8cG9seWdvbiBpZD0iUGF0aCIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIwIDYgMS40MSA0LjU5IDUgOC4xNyAxMi41OSAwLjU4IDE0IDIgNSAxMSI+PC9wb2x5Z29uPjwvc3ZnPg==');
      background-repeat no-repeat
      background-position center center
      color white
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      text-align center
      transform scale(0)
      transform-origin 50% 50%
      font-size inherit
    &:checked:before
      transform scale(1)
    @media mobile
      width 23px
      height 23px
      
  .field .select
    background-color transparent
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMSA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZyBpZD0iRHJvcGRvd24vTW9iaWxlL25vcm1hbCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE5Mi4wMDAwMDAsIC0yMy4wMDAwMDApIiBmaWxsPSIjQTEyODg1Ij4gICAgICAgIDxnIGlkPSJpY19hcnJvd19kcm9wX2Rvd25fYmxhY2tfMjRweC1jb3B5LTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Ni4wMDAwMDAsIDEzLjAwMDAwMCkiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgcG9pbnRzPSI2Ljg2Mjc0NTEgMTAgMTEuNzY0NzA1OSAxNSAxNi42NjY2NjY3IDEwIj48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=');
    background-repeat no-repeat
    background-position calc(100% - 16px) center
  
  .field .select--map-marker
    background-color transparent
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMSA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZyBpZD0iRHJvcGRvd24vTW9iaWxlL25vcm1hbCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE5Mi4wMDAwMDAsIC0yMy4wMDAwMDApIiBmaWxsPSIjQTEyODg1Ij4gICAgICAgIDxnIGlkPSJpY19hcnJvd19kcm9wX2Rvd25fYmxhY2tfMjRweC1jb3B5LTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Ni4wMDAwMDAsIDEzLjAwMDAwMCkiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgcG9pbnRzPSI2Ljg2Mjc0NTEgMTAgMTEuNzY0NzA1OSAxNSAxNi42NjY2NjY3IDEwIj48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4='), url($baseUrl+'/images/icon-map-marker.svg')
    background-repeat no-repeat, no-repeat
    background-position calc(100% - 16px) center, 0.5rem center
    background-size auto, 0.9rem
    padding-left 1.65rem
  
  .field .text
    &:read-only
      background-color #f8f8f8
    +placeholder()
      color lightgray
            
  .field .select,
  .field .text,
  .field .radio,
  .field .checkbox
    &:focus
      outline 0 none

  .field .select,
  .field .text
    color black
    &:focus
      &:not(:read-only)
      &:not(:valid)
      &:not(:invalid)
        outline 0 none
        border-color purple
        box-shadow 0 0 10px rgba(purple,0.3)
      
  .field .radio,
  .field .checkbox
    &:focus
      box-shadow 0 0 10px rgba(purple,1)
  
  .label-checkradio-multiline
    flex-display()
    flex-wrap nowrap
    flex-direction row
    line-height 130%
    input
      position relative
      margin-top 0.2rem
    span
      box-sizing border-box
      width calc(100% - 28px)
      padding-right: 1rem
    &.disabled
      opacity 0.5
      .radio,
      .radio:hover
        cursor: default;

  .field .text-calendar
    background-image url("data:image/svg+xml,%3Csvg viewBox='0 0 16 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Calendar-ICON' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ic_date_range_black_24px' transform='translate(-2.000000, -1.000000)'%3E%3Cpath d='M7.5,9.16666667 L5.83333333,9.16666667 L5.83333333,10.8333333 L7.5,10.8333333 L7.5,9.16666667 Z M10.8333333,9.16666667 L9.16666667,9.16666667 L9.16666667,10.8333333 L10.8333333,10.8333333 L10.8333333,9.16666667 Z M14.1666667,9.16666667 L12.5,9.16666667 L12.5,10.8333333 L14.1666667,10.8333333 L14.1666667,9.16666667 Z M15.8333333,3.33333333 L15,3.33333333 L15,1.66666667 L13.3333333,1.66666667 L13.3333333,3.33333333 L6.66666667,3.33333333 L6.66666667,1.66666667 L5,1.66666667 L5,3.33333333 L4.16666667,3.33333333 C3.24166667,3.33333333 2.50833333,4.08333333 2.50833333,5 L2.5,16.6666667 C2.5,17.5833333 3.24166667,18.3333333 4.16666667,18.3333333 L15.8333333,18.3333333 C16.75,18.3333333 17.5,17.5833333 17.5,16.6666667 L17.5,5 C17.5,4.08333333 16.75,3.33333333 15.8333333,3.33333333 Z M15.8333333,16.6666667 L4.16666667,16.6666667 L4.16666667,7.5 L15.8333333,7.5 L15.8333333,16.6666667 Z' id='Shape' fill='%23332D31'%3E%3C/path%3E%3Cpolygon id='Shape' points='0 0 20 0 20 20 0 20'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    background-repeat no-repeat
    background-position rem(10px) center
    background-size rem(20px) rem(20px)
    padding-left rem(40px)
    
.field-bordered-top
  position relative
  margin-top 1rem
  &:before
    content ''
    position absolute
    background-color #F7F7F7
    display block
    top 0
    left 1rem
    right 1rem
    height 6px
    margin-bottom 1rem
  &.bordered-full
    &:before
      left 0
      right 0

// // .flex-cell > .label + .value
// .flex-cell
//   flex-display()
//   flex-direction row
//   justify-content space-between
//   align-items baseline
//   .label
//     padding-right 1rem
    
// .flex-cell-header
//   border-top 1px solid #F5F5F5
//   border-bottom 1px solid #F5F5F5
//   padding 0.5rem 1rem 0.5rem 1rem
//   margin-top 0.5rem
//   &:first-of-type
//     margin-top 0
//     border-top 0 none

.icon-questionmark
  background-image: url("data:image/svg+xml,%3Csvg width='6px' height='10px' viewBox='0 0 6 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Desktop' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='18_Paso-4_Pago' transform='translate(-766.000000, -201.000000)' fill='%23ffffff'%3E%3Cg id='Realiza-tu-pagp' transform='translate(0.000000, 100.000000)'%3E%3Cg id='Group-2' transform='translate(766.000000, 101.000000)'%3E%3Cpolygon id='Path' points='3.33333333 9.33333333 2 9.33333333 2 8 3.33333333 8'%3E%3C/polygon%3E%3Cpath d='M4.71333333,4.16666667 L4.11333333,4.78 C3.63333333,5.26666667 3.33333333,5.66666667 3.33333333,6.66666667 L2,6.66666667 L2,6.33333333 C2,5.6 2.3,4.93333333 2.78,4.44666667 L3.60666667,3.60666667 C3.85333333,3.36666667 4,3.03333333 4,2.66666667 C4,1.93333333 3.4,1.33333333 2.66666667,1.33333333 C1.93333333,1.33333333 1.33333333,1.93333333 1.33333333,2.66666667 L1.21325172e-12,2.66666667 C1.21325172e-12,1.19333333 1.19333333,-1.86517468e-14 2.66666667,-1.86517468e-14 C4.14,-1.86517468e-14 5.33333333,1.19333333 5.33333333,2.66666667 C5.33333333,3.25333333 5.09333333,3.78666667 4.71333333,4.16666667 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 6px
  background-repeat no-repeat
  background-position center center

.field-helper
    $size = 18px
    transition ease background 300ms
    display inline-block
    background-color purple
    width $size
    height $size
    border-radius 100%

.field-helper-wrapper
  .field-helper
    position absolute
    right 0
    top -28px

  @media mobile
    text-align right
    .field-helper
      margin-bottom 0.5rem

/*
  Further reading 
  https://stackoverflow.com/questions/25425506/how-to-change-border-color-of-required-input-fields
  This works with: 
  - validation-on-submit.js
  - validation-on-keyup.js
*/
.content-form-tovalidate
  .input-field
  fieldset
  input[type=text]
  input[type=textarea]
  input[type=email]
  input[type=password]
  select
  textarea
  // Get rid of '!important'
    &.show-validity
      &.valid
        border-color green !important
        &:focus
          box-shadow 0 0 10px rgba(green,0.3) !important
        & + .input-invalid-message
          display none
    &.show-validity
      &.invalid
        border-color: red !important
        &:focus
          box-shadow 0 0 10px rgba(red,0.3) !important
        & + .input-invalid-message
          display block
  .input-invalid-message
    display: none
    padding-left 0.5rem
    color red
    &:first-of-type
      padding-top 0.2rem
    @media mobile
      line-height 120%
    @media nonmobile
      font-size 12px
    &.show-validity
      display block

.field-tooltip-wrapper
  position relative
  &.field-helper-wrapper
    .field-helper-tooltip
      bottom 3rem
      
.field-helper-tooltip
  $shadow = 0 0 10px rgba(#000,0.2);
  $borderColor = lighten(#BEBEBE,20%)
  width 180px
  left calc( 100% - 2rem)
  position absolute
  box-shadow $shadow
  border 1px solid $borderColor
  .tooltip-inner
    display block
    background #f7f7f7
    padding 1rem
    position relative
    z-index 100
  &:after
    $size = 8px
    content ''
    z-index 90
    position absolute
    width: 0; 
    height: 0; 
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid #f7f7f7;
    bottom -8px;
    left 1rem;
    filter:
      drop-shadow(0 1px 0 darken($borderColor,10%)) \
      drop-shadow(1px 1px 3px rgba(black,0.2)) \;

.field-wrapper-telephone
  $codeWidth = 140px;
  .field-code
    width: $codeWidth;
  .field-number
    width: "calc(100% - %s)" % $codeWidth;
    padding-left:1rem;

.field-termsconditions
  label
    flex-display()
    flex-direction row
    align-items flex-start
  .checkbox-wrapper
    width 32px
  .label-wrapper
    width calc(100% - 32px)
    line-height 145%

.form-main-actions
  @extend $fieldBordered
  &:before
    top 0
  
  .grid-2
    align-items start
  &--reverse-onmobile
    @media mobile
      .item:first-child
        grid-row 2

.field-arrival
  transition background ease 300ms, opacity ease 300ms
  // &.disabled input.text-calendar
  //   background-color #EDEDED
  &:hover
    cursor not-allowed
