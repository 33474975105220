/*!
Theme Name: Volaris Subscriptions
Theme URI: http://caravelo.com
Author URI: http://caravelo.com/
*/

// Our boilerplate 
$baseUrl = 'https://volaris.caravelo.com'

@require 'stylus/base/default'

// Variables, colors, functions, etc
@require 'stylus/global'
@require 'stylus/base/print.css'
@require 'stylus/base/reset'

html, body
  margin:0
  padding:0
  min-height:100vh
  width:100%
  -webkit-text-size-adjust: 100%;

body
  flex-display()
  flex-direction column
  font-family:body-font
  color: #111
  font-size: 16px
  font-smooth: auto
  line-height textlh
  @media mobile
    min-width:rem(320px)

main
  position relative
  width 100%

for i in 0 .. 10
  .p{i}
    padding unit(i,'rem')
  .p{i}_5
    padding unit(i + 0.5,'rem')
  .m{i}
    margin unit(i,'rem')
  .mt{i}
    margin-top unit(i,'rem')
  .mb{i}
    margin-bottom unit(i,'rem')
  .ml{i}
    margin-left unit(i,'rem')
  .mr{i}
    margin-right unit(i,'rem')
  .pt{i}
    padding-top unit(i,'rem')
  .pb{i}
    padding-bottom unit(i,'rem')

// Custom Padding
.pt05
  padding-top 0.5rem
.pb05
  padding-bottom 0.5rem
@media nondesktop
  for i in 1 .. 2
    .pt{i}-nondesktop
      padding-top unit(i,'rem')
    .pb{i}-nondesktop
      padding-bottom unit(i,'rem')
    .mb{i}-nondesktop
      margin-bottom unit(i,'rem')
  .pt4,.pt5
    padding-top 2rem
  .pb4,.pb5
    padding-bottom 2rem
.ml-auto
  margin-left auto
      
@media nonmobile
  for i in 1 .. 2
    .p{i}-nonmobile
      padding unit(i,'rem')
    .pt{i}-nonmobile
      padding-top unit(i,'rem')
    .pb{i}-nonmobile
      padding-bottom unit(i,'rem')
    .ml{i}-nonmobile
      margin-left unit(i,'rem')
    .mr{i}-nonmobile
      margin-right unit(i,'rem')
      
@media mobile
  for i in 0 .. 2
    .p{i}-mobile
      padding unit(i,'rem')
    .pt{i}-mobile
      padding-top unit(i,'rem')
    .pb{i}-mobile
      padding-bottom unit(i,'rem')
    .mt{i}-mobile
      margin-top unit(i,'rem')
    .mb{i}-mobile
      margin-bottom unit(i,'rem')
    .ml{i}-mobile
      margin-left unit(i,'rem')
    .mr{i}-mobile
      margin-right unit(i,'rem')
      
  .pt4
    padding-top 1.5rem
  .pt5
    padding-top 2rem
  .pb4
    padding-bottom 1.5rem
  .pb5
    padding-bottom 2rem
    
// Custom Margin
.mtauto
  margin-top auto
.mc
  margin-left auto
  margin-right auto


// Content you shouldn't append one inside the other
.content-wrapper
  div-wrapper(1200px)
.content-980
  div-wrapper(980px)
  
// Width
widthGrid = 250, 350 , 470, 550, 680;
for key in widthGrid
  .width-{key}
    max-width unit(key,'px')
    
// .bgwhite a
//   color blue
//   text-decoration underline

// Flex-image and bg-full-wrapper usually are displayed together
.flex-image
  align-items: center;
  @media nonmobile
    height 52vw
    min-height 450px
    max-height 600px

.bg-full-wrapper
  position relative
  height 100%
  .bg-full
    absolute-full()
    background-repeat no-repeat
    &.bg-full-right-bottom
      background-position right bottom
    &.bg-full-left-bottom
      background-position left bottom
    &.bg-full-center-bottom
      background-position center bottom
  @media nondesktop
    .bg-full
      background-size 90%
  @media mobile
    .bg-full
      background-size contain
      &.bg-full-left-bottom
        background-position center center
      &.bg-full-right-bottom
        background-position 20% center
  
// Item
$grayBorder = 2px solid #F5F5F5

$fieldBordered
  position relative
  &:before
    content ''
    position absolute
    left 0
    right 0
    background #F7F7F7  
    height 10px
    box-shadow inset 0 2px 0 #E7E7E7

.bordered-top
  border-top $grayBorder

.bordered-bottom
  border-bottom $grayBorder

.item-bordered-gray
  border $grayBorder
  border-radius 2px

.bordered-bottom-thick
  @extend $fieldBordered
  &:before
    bottom 0

.op-close
  line-height: 0
  svg
    width 1rem
    
// Fullbar Notification, Eg; Promo code
.fullbar-notification
  display: none
  position relative
  overflow hidden
  transition height ease 350ms
  .content-wrapper
    transition ease opacity 300ms, ease transform 200ms
    transform translateY(0)
    filter: blur(0);
  .op-close
    display block
    position absolute
    right 1rem
    top 0
  &.closed
    .content-wrapper
      opacity 0
      transform translateY(-0.5rem)
  a
    text-decoration: underline;

// Style for text
a.underlined-onhover
  &:hover
    text-decoration underline

.icon-checked
  position relative
  &:before
    content ""
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTQgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICA8cG9seWdvbiBpZD0iUGF0aCIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIwIDYgMS40MSA0LjU5IDUgOC4xNyAxMi41OSAwLjU4IDE0IDIgNSAxMSI+PC9wb2x5Z29uPjwvc3ZnPg==');
    background-repeat no-repeat
    background-position center center
    background-color green
    color white
    position absolute
    border-radius 100%
    top 50%
    margin-left -2rem
    margin-top -11px
    text-align center
    font-size inherit
    // See: resources/assets/volaris/subscriptions/components/animations.styl
    animation fadeIn forwards 200ms
  // &.icon-checked-outlined
  &.icon-checked-inlined // text should be left aligned
    padding-left 2rem

h2.icon-checked:before,
h4.icon-checked:before
  width 24px
  height 24px

p.icon-checked
  &.icon-checked-inlined
    padding-left:1.6rem
  &:before
    width 1.2rem
    height 1.2rem
    background-size 0.7rem
    margin-left:-1.6rem

@media nonmobile
  .onmobile
    display none

.volaris-loader
  img
    display block
    max-width 60px
    margin-left auto
    margin-right auto

.a-action-icon
  position relative
  display flex
  align-items center
  svg
    height 100%
  .icon
    height 1rem
  .value
    padding-left:0.4rem

// Spinner animation
@keyframes spinnerAnimation
 0%
  opacity 1
 100%
  opacity 0

// Components - Default
@require 'stylus/components/grid'

// Partials
@require 'stylus/components/colours'
@require 'stylus/components/animations'
@require 'stylus/components/notifications'
@require 'stylus/components/btn'
@require 'stylus/components/entry-content'
@require 'stylus/components/header'
@require 'stylus/components/footer'
@require 'stylus/components/lightbox'
@require 'stylus/components/tooltip'
@require 'stylus/components/accordion'

@require 'stylus/components/index'
@require 'stylus/components/index-hero-wrapper'
@require 'stylus/components/index-faq'
@require 'stylus/components/index-rating'

@require 'stylus/components/page-hero-wrapper'
@require 'stylus/components/page-hero-wrapper-promo'

@require 'stylus/components/subscriptions-common' // Common, nav, benefits, other things
@require 'stylus/components/subscriptions-summary' // Summary Item on Step 2, Step 3
@require 'stylus/components/subscriptions-select' // STEP 1
@require 'stylus/components/subscriptions-select-luggage' // STEP 2
@require 'stylus/components/subscriptions-spreedly-card' // STEP 3
@require 'stylus/components/subscriptions-register' // Step 3
@require 'stylus/components/subscriptions-confirmation' // Step 3
@require 'stylus/components/subscriptions-tua' // TUA info

@require 'stylus/components/forms'
@require 'stylus/components/flex-cell'
@require 'stylus/components/dropdown-box'
@require 'stylus/components/countdown'
@require 'stylus/components/promo-tag'
@require 'stylus/components/hero-countdown'


// Components - Externalssss
// @require 'bower_components/font-awesome-stylus/index'
