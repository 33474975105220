.hero-countdown
  height: auto

  #promo-h1,
  #promo-h3,
  .btn-wrapper
    @media mobile
      text-align: center

  #promo-h1
    @media mobile
      font-size: 24px

  #promo-h3
    @media mobile
      font-size: 16px

  .bg-volarisplane
    @media mobile
      min-width: auto

