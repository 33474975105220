.lightbox-wrapper
  position fixed
  top 0
  left 0 
  right 0
  bottom 0
  overflow-y auto
  z-index 650
  
  @media (pointer: coarse) {
    overflow-y scroll
    -webkit-overflow-scrolling: touch;
  }
  
  .lightbox-canvas
    absolute-full()
    position fixed
    background rgba(black,0.55)
    z-index 50
  
  .lightbox-content
    position relative
    z-index 100
    top 5%
    padding-bottom 3rem
    padding-left 0.5rem
    padding-right 0.5rem
    // When is not active
    opacity 0
    transform translateY(0.5rem)
  
  .lightbox-inner
    box-shadow 0 0 30px rgba(black,0.3)
    @media nonmobile
      max-width 1200px
    
  header
    position relative
    .op-close
      position absolute
      right 1rem
      top 1.25rem
      &.up
        top .5rem
      
  // Animation
  &.on-transition
    animation fadeIn forwards 200ms
    
  &.active
    .lightbox-content
      transition ease opacity 300ms, ease transform 300ms
      opacity 1
      transform translateY(0)
    

@keyframes fadeIn
  0%
    opacity 0
    transform translateY(0.5rem)
  100%
    opacity 1
    transform translateY(0)

.width-550
  width 550px

.width-470
  width 470px
  @media mobile
    width auto