.flex-page-hero-wrapper
  flex-display()
  align-items center
  height 200px
  position relative
  h1
    font-size 40px
    line-height 135%
  
  .content-wrapper
    z-index 110
    position relative
  .bg-clouds
    absolute-full()
    overflow hidden
    z-index 100
    .bg
      width 100%
      position absolute
      bottom 0
    .bg-bottom-clouds
      height 100%
      background-repeat repeat-x
      background-position 50% -20% // x y
      transform scale(1)
      z-index 50
    .bg-bottom-gradient
      height 50%
      z-index 40
  &--search-flight 
    height 15rem
    .bg-clouds .bg-bottom-clouds
      background-position 50% -40%
    @media nonmobile
      .content-wrapper
        transform translateY(-1rem)
  @media mobile
    h1
      font-size 28px