/*
  @see variables here resources/assets/volaris/subscriptions/stylus/global.styl
*/

for key,value in colors
  .bg{key}
    background-color value
  .{key}
    color value

.a-lightblue a
  color lightblue
  &:hover
    text-decoration underline

.a-purple a
  color purple
  &:hover
    text-decoration underline

.purple
  color purple