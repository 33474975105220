// .flex-cell > .label + .value
.flex-cell
  display flex
  flex-direction row
  justify-content space-between
  align-items baseline
  .label
    padding-right 1rem
    min-width 120px
    
.flex-cell-header
  border-top 1px solid #F5F5F5
  border-bottom 1px solid #F5F5F5
  padding 0.5rem 1rem 0.5rem 1rem
  margin-top 0.5rem
  &:first-of-type
    margin-top 0
    border-top 0 none

.flex-cell-dashed
  $gap = 0.8rem
  display flex
  justify-content flex-start
  .item-line
    position relative
    width 24px
    max-width 20%
    &:after
      content ''
      position absolute
      width 100%
      height 1px
      background black
      right 0
      top 50%
  .item:first-child
    padding-right: $gap
    max-width 40%
  .item:last-child
    padding-left: $gap
    max-width 40%
  &--spaced
    justify-content space-between
    .item
      flex-grow 1
    .item-line
      width 100%