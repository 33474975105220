.dropdown-box
  position fixed
  top 0
  // right 0
  z-index 600
    
  box-shadow 0 2px 4px #757575
  background white
  color black
  border-radius 0 0 3px 3px
  padding 1rem  
  font-size 14px
  
  .label-top
    padding-bottom 0.5rem
    padding-left 0.8rem // same as .text & .select padding-left
    display block
    

  &:before
    content ''
    width: 0; 
    position absolute
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top -7px
    right 24px
  
  &.on-transition
    animation fadeIn forwards 200ms
    transform-origin 50% 0

.dropdown-header-nav
  li
    display block
    padding 0.5rem 1.1rem
    &:first-child
      padding-top 0
    &:last-child
      padding-bottom 0
      
.dropdown-header-tools-list
  .select
    min-width 195px