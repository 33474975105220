.field-tax-info
  position relative

#lightbox-subscriptions-tua  
  .fade-enter-active
    transition: all ease 400ms
    transition-delay 100ms
  .fade-leave-active
    transition opacity ease 200ms;
  .fade-enter
    opacity 0
    transform translateY(0.5rem);
  .fade-leave-to 
    opacity 0

// #form-subscriptions-tua
  // transition opacity ease 300ms, transform ease 300ms;
  // opacity 0
  // transform translateY(1rem)  
  // &.loaded
  //   transform translateY(0)
  //   opacity 1