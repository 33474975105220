.box-notification
  position relative
  overflow: hidden
  &--error,
  &.notification-error
    background #D32F2F
    color white
  &.notification-success
    background #2E7D32
    color white
  .entry-content
    padding 0.75rem 1rem
  .op-close
    position absolute
    top 1rem
    right 1rem
    & + .entry-content
      padding-right 2rem
  &.on-transition
    transition ease height 300ms, ease margin-bottom 300ms