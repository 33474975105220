body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,
h5,h6,pre,form,fieldset,input,textarea,
p,blockquote,th,td,figure,nav,main,video,
button,a,section,aside
	margin:0; 
	padding:0; 
	box-sizing(border-box)
	
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section
	display: block

h1,h2,h3,h4,h5,h6 
	font-weight: normal 
	line-height: textlh

a 
	text-decoration:none
	// outline:0 none
	color:inherit

a img 
	border:0 none;

hr 
	border:0 none;
	display:block;

blockquote
	margin-left:25px; 

fieldset
	border: none;

input[type="submit"]
	border:0 none; 

.ul-horz
	list-style none
	& > li 
		display:inline-block 
		position:relative

.ul-vert 
	margin-left:0
	list-style none

.ul-vert li 
	line-height: textlh

.right, img.alignright 
	float:right

.left, img.alignleft
	float:left

.inline 
	display:inline

.inline-block 
	display: inline-block

.none, .ui-tabs-hide 
	height:0 !important
	width:0 !important
	opacity:0
	display:none !important

.hide
	text-indent: -9999999px

.hidden
	visibility:hidden

.relative
	position: relative

.absolute
	position: absolute

.block
	display: block
	box-sizing:border-box

.capitalize
	text-transform: capitalize

.uppercase 
	text-transform: uppercase

.lowercase
	text-transform: lowercase

.text-center
	text-align: center

.text-right
	text-align: right

.text-linethrough
	text-decoration: line-through;
	
.nowrap
	white-space:nowrap

.whitenormal
	white-space:normal
	vertical-align:top
	
.vsub
	vertical-align: sub
	
pre
	display: inline-block
	background-color:#f8f8f8
	padding:0rem 0.5rem
	border-radius 5px
	border 1px solid rgba(black,0.15)
	vertical-align middle
	color black
	@media mobile
		white-space: pre-wrap; 
		word-break break-all
	
button
	border 0 none
	box-shadow 0 none
	font-size inherit
	font-family inherit
	// &:focus
	// 	outline 0 none
	&:hover
		cursor pointer

::selection 
	background: purple; 
	color:white;

::-moz-selection
	background: purple; 
	color:#fff; 
	text-shadow:0 0 1px #333;

// Verital Aligned content
.table-wrap
	display:table
	width:100%
	height:100%
	.table-cell 
		display: table-cell;
		vertical-align:middle;

// This is custom for VOLARIS 
.strong,
.bold
	font-weight bold

h3
	font-size 24px
h4
	font-size 18px
h5
	font-size 16px
  
// [class*="fsz"]{
//   display: inline-block;
// }

.fsz22
	font-size 22px
	line-height 27px
.fsz12
	font-size 12px
	line-height 15px
.fsz14
	font-size 14px
	line-height 19px
.fsz18
	font-size 18px
.regular
	font-weight 300
.fsz10
	font-size 10px
	line-height 13px
