.flex-index-hero-wrapper
  flex-display()
  position relative
  height 540px
  align-content center
  h1
    line-height:135%
  h1, h3, .btn-wrapper
    position relative
  .btn-wrapper, h3
    z-index 200
  h3
    font-weight normal
  .content-wrapper
    position relative
    z-index 200
    
  .bg-volarisplane
    background-repeat no-repeat
    z-index 100
  
  .bg-clouds
    absolute-full()
    overflow hidden
    z-index 100
    .bg
      width 100%
      position absolute
      bottom 0
      height 50%
    .bg-bottom-clouds
      transition ease opacity 100ms, ease transform 300ms
      background-repeat repeat-x
      // background-position center 0% // x y
      transform scale(1)
      z-index 50
      &.unloaded
        transform scale(1) translateY(0.5rem)
        opacity 0
    .bg-bottom-gradient
      z-index 40
      
  @media desktop
    $planePosition = 4rem
    h1
      font-size 70px
      z-index 100
    h3
      font-size 30px
    .bg-volarisplane
      absolute-full()
      right 0
      height 100%
    .content-wrapper
      margin-top $planePosition
      padding-bottom $planePosition
      .bg-volarisplane
        background-position 98% $planePosition
  
  @media nondesktop
    .content-wrapper
      text-align center
      padding-top 3rem
    .bg-volarisplane
      width 100%
      max-width 480px
      margin-left auto
      margin-right auto
      height 200px
      background-position center center
      background-size contain
     

  @media mobile
    h1, h3, .btn-wrapper
      text-align left
    h3
      padding-top:1rem