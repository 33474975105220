.content-wrapper-promo
  @media tablet
    background-color: #afd9ef;
    max-width: 100% !important;
    text-align: center;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: inherit !important;
    padding-bottom: 20px;
    padding-top: 20px;
  @media mobile
    background-color: #afd9ef;
    max-width: 100% !important;
    text-align: center;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: inherit !important;
    padding-bottom: 20px;
    padding-top: 20px;

.content-wrapper-promo 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.banner 
  position: relative;
  img 
    display: block;
    width: 100%;
    max-height: auto;

.promo-btn
  background-color: #62bd19; 
  color: #fff;

#promo-h1
  font-size: 3.5em;

#promo-h3
  font-size: 24px
  max-width: 50%
  @media tablet
    max-width: 100%
  @media mobile
    max-width: 100%
    