// Subscriptions Spreedly Card STEP 3
.CvoForm
    &-field--error
      color #F00020
      font-size 0.85rem
    &-paymentAuthorizationError
      background red
      width 100%
      color white
      margin-top 1rem
      padding 1rem
      display none

.vclubPaymentPage
  .CvoCardSpreedly
    &-validationError
      font-size 0.85rem

.vpassBookingPage
.vpassPaymentPage
  .CvoCardSpreedly
    margin 0
    padding 0

    .columns 
      display flex
      width 100%
      margin-bottom 1.7em
      .column
        width 100%
        input
          font-size 0.875rem
          font-style normal
          font-weight 400
          line-height 24px

      .is-two-thirds
        margin-right 40px
        width 100%

        @media mobile
          width 100%
          margin-right 0

    &-expiryInput
      display flex
      width 100%
      div:first-child
        margin-right 15px

    &-cardInput
    &-cvvInput
      height 45px 
      padding-top 0 !important
      padding-left 0 !important
      padding-right 0 !important
      height 58px !important
      border none !important
    &-input
      width 100%
      margin 0
      padding 0.6rem 0.8rem
      border-radius 2px
      border 1px solid #e7e7e7
    &-label
      color #757575
      display block
      font-size 0.75rem
      margin-bottom 0.5rem

      @media mobile
        width 100%
        margin-right 0

  #fieldErrorNotification
  #validationNotificationError
  #infoBillingEmail
    display none

.vpassBookingPage
  #validationNotificationError
    margin-top -20px
    margin-bottom 20px

iframe[name^="spreedly"]
  height 60px !important

.hidden
  display: none;

#challenge-modal
  position fixed
  z-index 999999
  width 100vw
  height 100vh
  top 0
  left 0
  background rgba(255, 255, 255, 0.5)
  padding 10vh 10vw

#challenge
  width 100%
  height 100%
  box-shadow 0 0 5px 5px rgba(0, 0, 0, 0.3)
  background #ffffff
  border 1px solid black

.spreedly-challenge-iframe
  width 100%
  height 100%

.CvoCardSpreedly-validationError
  color #F00020