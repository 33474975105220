.top
  left 40%
  top 4%

.bottom
  left 40%
  top 90%

.left
  left 4%
  top 49%

.right
  top 49%
  left 90%

.tooltip-message {
  display inline-block
}

.flex-weekly--item-tooltip {
  padding-top 20px
}

#tooltip 
  $backgroundColor = #f7f7f7
  $shadow = 0 0 10px rgba(#000,0.2);
  $borderColor = lighten(#BEBEBE,20%)
  
  box-shadow $shadow
  background #f7f7f7
  position absolute
  z-index 10000
  transition 100ms ease
  border 1px solid $borderColor
  max-width 220px
  
  .tooltip-inner
    text-align left
    display block
    font-size 14px
    padding 1rem
    background #f7f7f7
    position relative
    z-index 100
  
  /*
  &:after
    width 0
    height 0
    border-left 10px solid transparent
    border-right 10px solid transparent
    border-top 10px solid $backgroundColor
    content ''
    position absolute
    left 50%
    bottom -10px
    margin-left -10px
    filter:
      drop-shadow(0 1px 0 darken($borderColor,10%)) \
      drop-shadow(1px 1px 3px rgba(black,0.2)) \;


  &.top:after
    border-top-color transparent
    border-bottom 10px solid #111
    top -20px
    bottom auto

  &.left:after
    left 10px
    margin 0

  &.right:after
    right 10px
    left auto
    margin 0
  */
    
  &.active
    animation fadeIn forwards 200ms
      
  @keyframes showTooltip
    0%
      opacity 0
    100%
      opacity 1
