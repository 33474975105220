// Animation keyframe used on multiple places
@keyframes fadeIn
  0%
    opacity 0
    transform translateY(0.5rem)
  100%
    opacity 1
    transform translateY(0)

@keyframes fadeLayer
  0%
    opacity 0
  100%
    opacity 1

.fade-in
  animation fadeIn forwards 400ms

.faede-out
  animation fadeIn forwards 400ms
  animation-direction reverse