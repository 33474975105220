.promo-tag
  background-color: #00CD0A
  font-size: 24px
  font-weight: 700
  line-height: 28.8px
  margin: 16px 0
  padding: 8px 12px
  width: fit-content
  
  @media tablet
    margin: 16px auto

  @media mobile
    font-size: 16px
    margin: 16px auto