.header-wrapper-index
  position relative
  z-index 590
  width 100%
  & + main
      padding-top: 0

.header-top
  padding 0.5rem 0
  align-content center  
  @media retina15
    padding 1rem 0
  .top-logo
    a, img
      display block
  .top-menu
    vertical-align middle
    p
      display inline-block
      font-size 14px
      line-height 100%
      vertical-align sub

  // Select language
  .top-menu-language
    margin-right 1rem
    position relative
    @media desktop
      padding-right 1rem
      &:after
        position absolute
        right 0
        top 4px
        content ''
        background-color #BEBEBE
        width 1px
        height 24px
        
  // Select menu user-select
  .top-menu-user
    @media mobile
      &--value
        max-width 96px
        text-overflow: ellipsis;
        overflow hidden
        white-space nowrap
        display inline-block
      
  .top-menu-user-register
    svg
      vertical-align sub
      padding-right:0.3rem
      
  // Select menu - 3 vertical dots
  .top-menu-nav-trigger a
    display inline-block
    padding-left 1rem
    padding-right 1rem
    position relative
    top 1px
    @media desktop
      display none

// Main horizontal menu, displayed on desktop
.header-nav
  a
    transition ease color 200ms
    &:hover
      color darken(white,20%)
  @media desktop
    padding-top 0.5rem
    padding-bottom 0.75rem
    li
      padding-right 3rem
    &:last-child
      padding-right 0

@media nondesktop
  .header-wrapper .header-nav
    display none