.hero-subscriptions-confirmation
  position relative
  .item-image
    background-color #6CABE7
    background-image url($baseUrl+'/images/subscriptions-confirmation-hero-clouds.png')
    background-size cover
    background-repeat no-repeat
    background-position center center
    img
      display block
      max-width 370px
      margin 0 auto
  @media desktop
    &:before
      width 50%
    .item-description
      padding-left 3.5rem
  @media nondesktop
    overflow hidden
    // .item-description
    //   padding-left 1rem
    //   padding-right 1rem
    .content-wrapper
      // max-width 100%
      // padding-left 0
      // padding-right 0
      position relative
      z-index 100
  @media tablet
    .item-image img
      margin 0 auto
      max-width 420px
    .item-description
      h2
        font-size 1.2rem
  @media mobile
    .item-image 
      img
        width 60%
      
#flight-info-pax-tabs
  .flex-weekly-tabs--list-item.active:first-child:after
    left 0
  .flex-weekly-tabs--list-item.active:last-child:after
    right 0