.content-rating
  @media desktop
    .rating-swiper-wrapper
      grid-column-gap: unit(4, 'rem');
    .item
      flex-direction column
  @media nondesktop
    .item
      padding-bottom 2rem
      flex-direction column
    .el-content
      max-width 270px;
  @media tablet
    .flex-2-tablet
      justify-content center
      .item
        padding-left 0.5rem
        padding-right 0.5rem
  @media nonmobile
    .rating-swiper-button-nav
      display none
  @media mobile
    .rating-swiper-container
      position: relative;
      overflow: hidden;
      list-style: none;
      padding: 0;
      z-index: 1;
      width: 100%;
      max-width: 780px;
      margin: 0 auto;
    .rating-swiper-wrapper
      display flex
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition-property: transform;
    .rating-swiper-wrapper .item
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      transition-property: transform,-webkit-transform;
    .el-content
      max-width 230px;