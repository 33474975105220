// Subscriptions STEP 3
// Final giant subscriptions register
.grid-subscriptions-form
  display grid
  grid-template-columns 2fr 1fr
  .grid-2
    align-items start
    
  .item-form
    width 100%
    .label-top
      display inline-block
      padding-left 0.8rem // same as .text & .select padding-left
      padding-bottom 0.5rem
      font-size 12px
      color lightgray
      @media mobile
        font-size 1rem
      
  // Custom styles for specific FIELDS
  .field-wrapper-radio
    label
      margin-right 2rem
      
  .field-wrapper-telephone
    flex-display()
    // align-items flex-end
    .field-telephonenumber
      padding-left 1rem
      
  @media all and (min-width:901px)
    .item-form
      padding-right 2rem
  @media all and (max-width:900px)
    grid-template-columns 1fr
    // overflow hidden
    .item-form
      padding-right 0
  @media mobile
    .grid-2.pb2
      padding-bottom 0
    // .field
    //   padding-bottom 1rem
