//============================================================
// Mixins for stylus
//
// TODO
// use autoprefixer!!
//
//============================================================

clear()
	&:before
	&:after
		content: ""
		display: table
	&:after
		clear: both
		zoom: 1 if support-for-ie

//============================================================
// REM
//============================================================

font-size(value)
	if (value is inherit)
		return
	else
		font-size rem(value)
		
rem(value)
	u = unit(value)
	
	if (u is 'px')
		return unit(value/16, 'rem')
	
	else
		return unit(value, u)

//============================================================
// FLEX
//============================================================

vendor(prop, args)
	-webkit-{prop} args
	-moz-{prop} args
	-ms-{prop} args
	{prop} args

flex()
	-webkit-box-flex arguments
	-webkit-flex arguments
	-ms-flex arguments
	flex arguments


flex-display()
	display -webkit-box
	display -ms-flexbox
	display -webkit-flex
	display flex
	flex-flow wrap

	
flex-direction()
	-webkit-flex-direction arguments
	-ms-flex-direction arguments
	flex-direction arguments


//============================================================
// Appearance
//============================================================

filter()
	filter arguments
	-webkit-filter arguments


appearance()
	-webkit-appearance arguments
	-moz-appearance arguments
	appearance arguments
          

user-select()
	vendor('user-select', arguments)
	
//============================================================
// Columns
//
// 	selector(2,,true)
//
//============================================================
columns(count = 3, gap = 1rem , break = false )
	-webkit-column-count count
	-moz-column-count count
	column-count count
	-webkit-column-gap gap
	-moz-column-gap gap
	column-gap gap
	
	& ._cell
		-webkit-transform translateZ(0) if break
		-webkit-column-break-inside avoid if break
		page-break-inside avoid if break
		break-inside avoid-column if break

//============================================================
// Placeholder
//
// 	input
//		+placeholder()
//			values
//
//============================================================


placeholder()
	&::-webkit-input-placeholder
		{block}
	&:-moz-placeholder
		{block}
	&::-moz-placeholder
		{block}
	&:-ms-input-placeholder
		{block}

// //============================================================
// // Structure
// //============================================================
 
box-shadow()
	vendor('box-shadow', arguments)


box-sizing()
	vendor('box-sizing', arguments)


border-radius()
	vendor('border-radius', arguments)

//============================================================
// Animations
//============================================================
 
// transition()
// 	vendor('transition', arguments)

// transform()
// 	vendor('transform', arguments)
	
// transform-origin()
// 	vendor('transform-origin', arguments)


//============================================================
// Animations
//
// Example http://codepen.io/syndicatefx/pen/jcBmF
//
//============================================================

animation()
	vendor('animation', arguments)

backface-visibility()
	vendor('backface-visibility', arguments) 

perspective-origin()
	vendor('perspective-origin',arguments)