  .el-bluecircle-index
  $size = 32px
  width $size
  height $size
  display inline-block
  
  position relative
  background lightblue
  color white
  text-align center
  line-height 29px
  border-radius 100%
  z-index 50
  
  &:before,
  &:after
    content ''
    z-index -1
    position absolute
    border-radius 100%
  &::before
    $margin = -6px
    top $margin
    left $margin
    right $margin
    bottom $margin
    border 1px solid #EAEAEA
  &::after
    $margin = -12px
    top $margin
    left $margin
    right $margin
    bottom $margin
    border 1px solid #F5F5F5

.bgpalewhite
  .el-bluecircle-index::after
    border-color #F1F1F1

.ul-icons
  li
    padding-bottom 1rem

.flex-index-landing
  @media tablet
    .bg-full
      background-size 90%
  @media mobile
    flex-direction column-reverse
    .bg-full-wrapper
      height 300px
        
.content-index-benefits
  .icon
    width 26px
    display inline-block
  @media nonmobile
    .flex-2
      .item:first-child
        width 55%
        padding-left 5rem
      .item:last-child
        width 45%
  @media mobile
    .bg-full-wrapper
      height 300px
      