.subscriptions-nav
  position relative
  box-shadow inset 0 -1px 0 #E7E7E7
  .content-wrapper
    flex-display()
  .subscriptions-bar
    transition cubic-bezier(.75,.43,.35,.89) width 1s
    max-width 100%
    height 4px
    background purple
    position absolute
    bottom 0
    left 0
  .btn
    padding 0.5rem 1.5rem
  .subscriptions-content
    height rem(60px)
    &--title
      flex-display()
      svg
        width 1rem
      .icon
        margin-right 0.5rem
    
    
.subscriptions-benefits
  svg
    display inline-block
    vertical-align middle
    padding-right 0.75rem
  svg
    path
      fill lightgray
  .item-taxes .index-down
    display none
  @media nonmobile
    .item:nth-child(1)
      text-align left
    .item:nth-child(2)
      text-align center
    .item:nth-child(3)
      text-align right

.tooltip
  position: relative
  display: inline-block
  width: fit-content
  margin: auto
  &.tooltip-info:before
    content: '\0000a0'
    background-image: url($baseUrl+'/images/icon-info.svg')
    background-repeat: no-repeat
    background-position-y: 0.1rem
    background-size: 1rem
    width: 1rem
    display: inline-block
  .tooltip-text
    visibility: hidden
    position: absolute
    width: 16rem
    background-color: white
    font-size: 0.8rem
    text-align: center
    padding: 0.5rem
    box-shadow: inset 0px 0px 5px 1px rgba(128,128,128,1);
    z-index: 1
    opacity: 0
    transition: opacity 0.3s
    &:after
      content:'';
      position:absolute;
      background:inherit;
      box-shadow: inset -5px -5px 5px -4px rgba(128,128,128,1);
      z-index: -1
  &:hover
    .tooltip-text
      visibility: visible
      opacity: 1
  .tooltip-top
  .tooltip-bottom
  .tooltip-left
  .tooltip-right
    &:after
      width:20px;
      height:20px;
  .tooltip-top
  .tooltip-bottom
    margin-left: -9.5rem
    &:after
      left: 7.5rem;
  .tooltip-top
    bottom: 2.5rem
    &:after
      bottom:-10px;
      transform:rotate(45deg);
  .tooltip-bottom
    top: 2.5rem
    &:after
      top:-10px;
      transform:rotate(225deg);
  .tooltip-left
  .tooltip-right
    top: -1rem;
    &:after
      top: 1rem;
  .tooltip-left
    right: 4.5rem
    &:after
      left: 16.3rem;
      transform:rotate(315deg);
  .tooltip-right
    left: 4.5rem
    &:after
      right: 16.3rem;
      transform:rotate(135deg);
