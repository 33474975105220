.content-index-faq
  .faq-item
    position relative
    border-bottom 1px solid #EAEAEA
    display block
    
  .flex-faq-question
    flex-display()
    justify-content space-between
    font-size 1rem
    .question
      width calc(100% - 30px)
  
  .accordion-item-header
    rect
      transition ease transform 200ms
      transform scale(1)
      transform-origin center center
      
  .accordion-item-active
    & > .accordion-item-header
      rect
        transform scale(0)
        
  .faq-group-header
    border-bottom: 2px solid #EAEAEA